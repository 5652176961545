$hellbg: #888888;
$darkgrey: #444444;
$fontcolor: #444444;
$bg: rgba(240,240,240,0.95);
$contrast: rgb(110, 140, 180);
$footercontrast: #222222;
$white: #ffffff;
$darkit: rgba(0,0,0,0.15);
$reddit: rgba(180,60,60,0.1);
$whiteit: rgba(240,240,240,0.4);
