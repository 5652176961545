body {
  background-color: #ffffff;
  line-height: 2em;
}

.navbar {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

#logo {
  width: 30px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 768px) {
  #logo {
    width: 100px;
  }
}

@media (min-width: 992px) {
  #logo {
    width: 100px;
    left: 10px;
  }
}

#logo .wh {
  fill: #ffffff;
}

.logo-container {
  width: 35px;
  top: 0px;
  right: 0px;
  display: block;
  position: absolute;
}

@media (min-width: 768px) {
  .logo-container {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .logo-container {
    width: 120px;
    left: 10px;
    position: relative;
  }
}

.hero {
  transition: all 2s;
  opacity: 0;
  border-bottom: 2px solid rgba(240, 240, 240, 0.95);
}

.shrink .hero {
  opacity: 0.1 !important;
}

.navbar-brand {
  transform: scale(1.2);
  opacity: 0;
  transition: opacity 3s, transform 0.5s;
}

.navbar-brand .navbar-burger {
  margin-left: 0;
}

.navbar-brand .navbar-item {
  flex-shrink: 1;
  font-size: 1em;
  color: #6e8cb4 !important;
}

@media (min-width: 768px) {
  .navbar-brand .navbar-item {
    font-size: 1.2em;
  }
}

.loaded .navbar-brand {
  opacity: 1;
  transform: scale(1);
}

.loaded .hero {
  opacity: 1;
}

.footer {
  clear: both;
}

.has-text-centered ul, .has-text-centered ol {
  text-align: left;
  max-width: 80%;
  margin: 1em 0 1em auto;
}

.content h1 {
  margin: 2em 0;
  font-size: 1.2em;
}

@media (min-width: 768px) {
  .content h1 {
    font-size: 2em;
  }
}

.content h2 {
  font-size: 1.1em;
}

@media (min-width: 768px) {
  .content h2 {
    font-size: 1.7em;
  }
}

.content h1, .content h2, .content h3 {
  font-weight: 400;
  color: #6e8cb4;
}

.content a:active, .content a:link, .content a:visited, .content a:hover {
  color: #6e8cb4;
}

.content a:hover {
  color: #222222;
}

.rjgallery {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.rjgallery div {
  margin: 0.5em;
}

.rjgallery img {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.gallery {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 0.2em;
  background-color: #ffffff;
  border: 1px solid rgba(240, 240, 240, 0.95);
}

.gallery a {
  display: block;
  text-align: center;
}

#page-content {
  background-color: #ffffff;
  background-image: url("../../images/schneestern.png");
  background-repeat: no-repeat;
  background-position: bottom -120px right -80px;
}

table.kommission {
  max-width: 800px;
  margin: 0 auto;
}

table.sperren td {
  vertical-align: middle;
}

.status1 {
  background-color: green;
}

.status0 {
  background-color: red;
}

@media (max-width: 767px) {
  table {
    max-width: 95%;
    font-size: 0.9em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tbody tr {
    display: block;
    margin-top: 1em;
  }
  table tbody td {
    position: relative;
    display: block;
    text-align: left;
    border-bottom: solid 1px _palette(border);
    padding-left: 105px !important;
  }
  table tbody td:before {
    content: attr(data-label);
    float: left;
    width: 100px;
    position: absolute;
    left: 0px;
    display: inline-block;
    padding: 0.2em;
    margin-right: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
  }
  table tbody td.has-text-centered {
    text-align: left !important;
  }
}
