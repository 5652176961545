body {
  background-color: $white;
  line-height: 2em;
}

.navbar {
 box-shadow: 0 5px 5px 0 $darkit;
}

#logo {
  width: 30px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 10px;
  @include for_size(tpu) {
    width: 100px;
  }
  @include for_size(tlu) {
    width: 100px;
    left: 10px;
  }
  .wh {
    fill: $white;
  }
}
.logo-container {
  width: 35px;
  top: 0px;
  right: 0px;
  display: block;
  position: absolute;
  @include for_size(tpu) {
    width: 120px;
  }
  @include for_size(tlu) {
    width: 120px;
    left: 10px;
    position: relative;
  }
}
.hero {
  transition: all 2s;
  opacity: 0;
  border-bottom: 2px solid $bg;
}

.shrink {
  .hero {
    opacity: 0.1 !important;
  }
}

.navbar-brand {
  transform: scale(1.2);
  opacity: 0;
  transition: opacity 3s, transform 0.5s;
  .navbar-burger {
    margin-left: 0;
  }
  .navbar-item {
    flex-shrink: 1;
    font-size: 1em;
    @include for_size(tpu) {
      font-size: 1.2em;
    }
    color: $contrast !important;
  }
}

.loaded {
  .navbar-brand {
    opacity: 1;
    transform: scale(1);
  }
  .hero {
    opacity: 1;
  }
}

.footer {
  clear: both;
}

.has-text-centered {
  ul, ol {
    text-align: left;
    max-width: 80%;
    margin: 1em 0 1em auto;
  }
}

.content {
  h1 {
    margin: 2em 0;
    font-size: 1.2em;
    @include for_size(tpu) {
      font-size: 2em;
    }
  }
  h2 {
    font-size: 1.1em;
    @include for_size(tpu) {
      font-size: 1.7em;
    }
  }
  h1, h2, h3 {
    font-weight: 400;
    color: $contrast;
  }
  a {
    &:active,
    &:link,
    &:visited,
    &:hover {
      color: $contrast;
    }
    &:hover {
      color: $footercontrast;
    }

  }
}

.rjgallery {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  div {
    margin: 0.5em;
  }
  img {
    box-shadow: 0 5px 5px 0 $darkit;
  }
}

.gallery {
  box-shadow: 0 5px 5px 0 $darkit;
  padding: 0.2em;
  background-color: $white;
  border: 1px solid $bg;
  a {
    display: block;
    text-align: center;
  }
}

#page-content {
  background-color: $white;
  background-image: url('../../images/schneestern.png');
  background-repeat: no-repeat;
  background-position: bottom -120px right -80px;
}

table.kommission {
  max-width: 800px;
  margin: 0 auto;
}
table.sperren {
  td {
    vertical-align: middle;
  }
}
.status1 {
  background-color: green;
}
.status0 {
  background-color: red;
}

@include for_size(p) {
  table {
    max-width: 95%;
    font-size: 0.9em;
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tbody {
      tr {
        display: block;
        margin-top: 1em;
      }
      td {
        position: relative;
        display: block;
        text-align: left;
        border-bottom: solid 1px _palette(border);
        padding-left: 105px !important;
        &:before {
          content: attr(data-label);
          float: left;
          width: 100px;
          position: absolute;
          left: 0px;
          display: inline-block;
          padding: 0.2em;
          margin-right: 0.5em;
          font-weight: bold;
          text-transform: uppercase;
        }
        &.has-text-centered {
          text-align: left !important;
        }
      }
    }
  }
}
